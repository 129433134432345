.complianceCalendarFilter .MuiTextField-root {
  min-height: 25px !important;
}

.compliance__wrapper .confirm-checkbox label {
  min-width: fit-content !important;
}

/* .compliance__wrapper .confirm-checkbox .PrivateSwitchBase-root-194 {
  padding-top: 0;
} */
.compliance__wrapper {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.supplier-wrapper {
  min-width: 1050px;
}

.compliance__container {
  margin: 0 !important;
}

.table-compliance-padding {
  padding: 0 20px 20px 15px;
}

.compliance__wrapper .MuiTableContainer-root {
  min-height: 0vh
}

.confirm-checkbox .MuiButtonBase-root {
  padding: 0 !important;
}

.kontami-container {
  margin-left: 10px;
}

.supplier-container {
  min-width: 1050px;
  display: flex;
  padding-top: 8px;
  padding-bottom: 2px;
  border-bottom: 0;
}

.supplier-header {
  font-size: 13px;
}

.customer-code {
  width: 20%;
}

.supplier-code {
  width: 20%;
}

.supplier-groupTitle {
  color: rgb(85, 130, 50);
  margin-right: 5px;
}

.supplier-groupTitle-size {
  white-space: nowrap;
  resize: none;
  overflow: hidden;
}

.supplier-name {
  width: 30%;
}

.telephone-number,
.preFecture-code {
  width: 20%;
  font-size: 12px;
}

.confirm-checkbox {
  display: flex;
  min-width: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.checkbox-container {
  height: 50%;
}

.confirm-date {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: start;
  font-size: 11px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  /* padding-bottom: 15px; */
}

.compliance-data {
  /* min-width: 100vw; */
  background-color: red;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.compliance-row {
  display: flex;
  height: 70px;
  /* margin-top: 5px; */
  width: 100%;
  position: relative;
}

.compliance-max-height {
  height: 105px;
}

.compliance-third-row {
  height: 33%;
}

.compliance-cell-border {
  border-bottom: 1px solid grey;
}

.compliance-cell {
  padding-bottom: 2px;
}
/* rgb(59, 56, 56) */
/* .compliance-data .MuiTableCell-root {
  padding: 0;
} */

.compliance-row .MuiTableCell-root {
  padding: 0;
}

.compliance-data-row-1,
.compliance-data-row-2 {
  height: 50%;
  width: 1035px;
}

.double-radio-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.double-radio-checkbox-container {
  width: 85%;
  display: flex;
  justify-content: space-around;
}

.double-radio-checkbox .MuiRadio-root {
  padding: 0 !important;
  cursor: auto;
}

.double-radio-checkbox .MuiSvgIcon-fontSizeSmall {
  font-size: 0.9rem;
}

.product-code-cell {
  padding-left: 14px;
}

.color-blue {
  background-color: #f5faeb;
}

.text-red {
  color: red;
}

.back-red {
  color: white;
  background-color: red;
}

.buttonSpaceCompliance {
  margin-top: 20px;
}

.emptyHeightElement {
  width: 100%;
}

.text-align-last-cell {
  padding-left: 10px;
}

.button-compliance {
  width: 128px;
  height: 28px;
}

.hqConfirmContainer .wrapper_select_input .MuiOutlinedInput-root,
.approvalContainer .wrapper_select_input .MuiOutlinedInput-root {
  width: 115px;
}

.first-radio-checkbox,
.second-radio-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.compliance__wrapper .toolbar .calendar-item .MuiInputBase-root .MuiInputBase-input,
.compliance__wrapper .filterWeek-label {
  font-size: 12px !important;
}

.confirm-checkbox {
  min-width: 90.41px;
}

.checkbox-compliance .MuiTouchRipple-root {
  display: none;
}

.height18 {
  height: 18px !important;
}

.csv-container {
  margin-top: 3px;
}

.compliance__wrapper .checkbox-container .custom-checkbox {
  padding: 0 16px;
}

.confirm-checkbox .checkbox-container .custom-checkbox {
  padding-bottom: 20px;
}

.paddingTop15 {
  padding-top: 15px !important;
}

.paddingBottom15 {
  padding-bottom: 15px !important;
}

.compliance__wrapper .double-radio-checkbox .MuiSvgIcon-root {
  width: 15px !important;
  height: 15px !important;
}

@media (min-width: 1700px) {
  .double-radio-checkbox-container {
    width: 60%;
  }
  .col-1-full-width {
    width: 12% !important;
  }
  .col-2-full-width {
    width: 52% !important;
  }
  .col-3-full-width {
    width: 6% !important;
  }
  .text-align-last-cell {
    text-align: end;
    padding-right: 30px;
  }
}

@media (max-width: 1920px) {
  .col-1-full-width {
    width: 15.5% !important;
  }
  .col-2-full-width {
    width: 66% !important;
  }
  .col-3-full-width {
    width: 10% !important;
  }
}

@media (max-width: 1699px) {
  .col-1-full-width {
    width: 16.5% !important;
  }
  .col-2-full-width {
    width: 68% !important;
  }
  .col-3-full-width {
    width: 10% !important;
  }
}

@media (max-width: 1050px) {
  .compliance-content {
    width: 100%;
  }
  .compliance__container .table-scroll {
    /* overflow-x: auto; */
    width: 100%;
    /* margin-bottom: 40px; */
  }
}

@media (max-width: 1360px) {
  .compliance__container .btn-download {
    /* margin-top: 3px; */
    min-width: 130px !important;
    font-size: 13px;
  }

  .compliance__container .btn-download .MuiButton-root {
    min-width: 120px !important;
  }
  .compliance__container .btn-download .MuiButton-root span:first-child {
    font-size: 13px;
  }
}

@media (max-width: 1370px) {
  .compliance-header-col-1 {
    width: 7.95% !important;
  }
}

/* @media (max-width: 1422px) {
  .compliance__wrapper .toolbar {
    min-width: 1200px !important;
  }
} */
